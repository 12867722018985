var tableColumns = [{
  title: "流水ID",
  dataIndex: "manuscriptid",
  key: "manuscriptid",
  width: 140
}, {
  title: "流水类型",
  dataIndex: "advertisername",
  key: "advertisername",
  width: 180,
  scopedSlots: {
    customRender: "advertisername"
  }
}, {
  title: "用户",
  dataIndex: "channel",
  key: "channel",
  width: 100,
  scopedSlots: {
    customRender: "channel"
  }
}, {
  title: "数量",
  dataIndex: "taskname",
  key: "taskname",
  width: 100,
  scopedSlots: {
    customRender: "taskname"
  }
}, {
  title: "流水时间",
  dataIndex: "submittime",
  key: "submittime",
  width: 180,
  scopedSlots: {
    customRender: "submittime"
  }
}];
export { tableColumns };