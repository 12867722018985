var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "pointsManagement"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8,
      order: 3
    }
  }, [_c("div", {
    staticClass: "order"
  }, [_c("span", [_vm._v("1 col-order-4")]), _c("span", {
    staticClass: "describe"
  }, [_vm._v("总收入数量")])])]), _c("a-col", {
    attrs: {
      span: 8,
      order: 2
    }
  }, [_c("div", {
    staticClass: "order"
  }, [_c("span", [_vm._v("1 col-order-4")]), _c("span", {
    staticClass: "describe"
  }, [_vm._v("总转出数量")])])]), _c("a-col", {
    attrs: {
      span: 8,
      order: 1
    }
  }, [_c("div", {
    staticClass: "order"
  }, [_c("span", [_vm._v("1 col-order-4")]), _c("span", {
    staticClass: "describe"
  }, [_vm._v("用户当前持有")])])])], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "manuscriptid",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "channel",
      fn: function fn(channel) {
        return _vm._l(_vm.checkChannel(channel), function (item, key) {
          return _c("a-tag", {
            key: key,
            staticStyle: {
              margin: "2px"
            },
            attrs: {
              color: "blue"
            }
          }, [_vm._v(_vm._s(item))]);
        });
      }
    }, {
      key: "creatorname",
      fn: function fn(creatorname, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.creatoruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(creatorname))])];
      }
    }, {
      key: "taskname",
      fn: function fn(taskname, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.taskid, "taskDetails");
            }
          }
        }, [_vm._v(_vm._s(taskname))])];
      }
    }, {
      key: "advertisername",
      fn: function fn(advertisername, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.advertiseruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(advertisername))])];
      }
    }, {
      key: "submittime",
      fn: function fn(submittime) {
        return [_c("span", [_vm._v(_vm._s(_vm.fmtTimeVal(submittime)))])];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:bcManuscriptManage:manuscriptDetails"],
            expression: "['check:bcManuscriptManage:manuscriptDetails']"
          }],
          staticStyle: {
            "min-width": "auto",
            padding: "0",
            margin: "0"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showEditModal(item);
            }
          }
        }, [_vm._v("查看")])];
      }
    }])
  }, [_c("template", {
    slot: "status"
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")])], 2), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };